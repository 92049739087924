import React from 'react'
import './all.sass'
import { Link } from 'gatsby'

/**
 * Returns an HTML link tag with the appropriate name and URI parsed
 * from a Markdown file
 *
 * @param link markdown data to be parsed as a link to a DHF page
 * @returns {JSX.Element} link component
 */
const DHFLink = ({ link }) => {
  return <Link to={link.split('|')[2]}>{link.split('|')[1]}</Link>
}

export default DHFLink
